import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  downloadTemplate(event){
    this.disableButton(event)
    this.downloadPrefilledTemplate()
    this.enableButton(event)
  }

  disableButton(event){
    event.currentTarget.disabled = true
    event.currentTarget.classList.add('cursor-wait')
  }

  enableButton(event){
    event.currentTarget.classList.remove('cursor-wait')
    event.currentTarget.disabled = false
  }

  downloadPrefilledTemplate(){
    const paymentBankAccountIds = document.querySelectorAll("input[name='batch_transfer[external_bank_account_ids]'")
    const queryParams = Array.from(paymentBankAccountIds).map((element) => `external_bank_account_ids[]=${element.value}`).join('&')

    const url = `/batch_transfers/template?${queryParams}`

    const a = document.createElement("a")
    a.href = url
    a.click()
    a.remove()
  }
}