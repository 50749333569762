import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "stepContainer",
    "otpStepContainer",
    "resendOtpButton",
    "validationMessage",
    "otpLoadingStep",
    "otpInputsContainer",
    "otpInput"
  ]

  async generateOtp(event){
    event.preventDefault()
    this.resetOtpCountDown()
    this.stepContainerTarget.classList.add("hidden")
    this.otpStepContainerTarget.classList.remove("hidden")

    const url = event.currentTarget.dataset.urlValue

    try{
      const response = await fetch(url)
      const data = await response.json()

      if(data.success){
        this.setValidationMessage(true, data.message)
      } else {
        this.setValidationMessage(false, data.message)
      }
    } catch(error){
      this.setValidationMessage(false, "Something went wrong")
    }
  }

  async verifyOtp(url){
    const otps = this.otpInputTargets.map((otpInput) => {
      return otpInput.value.trim()
    })

    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify({ otps: otps })
      })

      const data = await response.json()

      if(data.success){
        // Submit the form
        document.getElementById("batchTransferForm").submit()
        this.removeLoadingUi()
      } else {
        this.setValidationMessage(false, data.message)
      }
    } catch(error){
      this.setValidationMessage(false, "Something went wrong")
    }
  }

  showLoadingUi(){
    this.otpLoadingStepTarget.classList.remove("hidden")
    this.otpInputsContainerTarget.classList.add("hidden")
  }

  removeLoadingUi(){
    this.otpLoadingStepTarget.classList.add("hidden")
  }

  submitOtp(event){
    event.preventDefault()
    const currentInput = event.currentTarget;

    if (event.key === 'Backspace') {
      const parentDiv = currentInput.parentElement;
      const prevDiv = parentDiv.previousElementSibling;

      if (prevDiv) {
        this.showLoadingUi()
        const prevInput = prevDiv.querySelector('input');
        if (prevInput) {
          prevInput.focus();
        }
        return
      }
    }

    if(currentInput.value.length > 1){
      currentInput.value = currentInput.value.slice(1, 2)
    }

    // Allow only integer input
    if (event.key === " " || !/^\d*$/.test(event.key)) {
      return
    }

    const allInputsFilled = this.otpInputTargets.every((otpInput) => {
      return otpInput.value.trim() !== '';
    })

    if(allInputsFilled){
      this.verifyOtp(event.currentTarget.dataset.urlValue)
    } else {
      this.setValidationMessage(false, "Invalid OTP")
    }
  }

  resetOtpCountDown(){
    this.resendOtpButtonTarget.disabled = true; // Disable the resend button
    this.resendOtpButtonTarget.classList.remove("cursor-pointer");

    let count = 60;

    const countdownInterval = setInterval(() => {
      if (count > 0) {
        this.resendOtpButtonTarget.innerText = `Resend OTP in ${count} seconds`;
        count--;
      } else {
        clearInterval(countdownInterval);
        this.resendOtpButtonTarget.innerText = "Resend OTP";
        this.resendOtpButtonTarget.classList.add("cursor-pointer");
        this.resendOtpButtonTarget.disabled = false; // Enable the resend button after countdown ends
      }
    }, 1000);
  }

  setValidationMessage(success, message){
    this.validationMessageTarget.classList.remove("hidden")

    if(success){
      this.validationMessageTarget.innerText = message
      this.validationMessageTarget.classList.add("text-green-500")
      this.validationMessageTarget.classList.remove("text-red-500")
    } else {
      this.validationMessageTarget.innerText = message
      this.validationMessageTarget.classList.add("text-red-500")
      this.validationMessageTarget.classList.remove("text-green-500")
    }

    setTimeout(() => {
      this.validationMessageTarget.classList.add("hidden");
    }, 3000);
  }

  pasteOtp(event){
    event.preventDefault();

    const clipboardData = event.clipboardData || "";
    const pastedDate = clipboardData.getData("text/plain") || "";
    const numericValues = pastedDate.replace(/\D/g, "");

    this.otpInputTargets.forEach((otpInput, index) => {
      otpInput.value = numericValues[index] || "";
    })

    if(numericValues.length === 6){
      const otpInput = document.querySelector('input[name="otp[6]"]');
      this.verifyOtp(otpInput.dataset.urlValue)
    } else {
      this.setValidationMessage(false, "Invalid OTP")
    }
  }

  focusNext(event) {
    event.preventDefault();
    const parentDiv = event.currentTarget.parentElement;

    this.validationMessageTarget.classList.add("hidden")

    const currentInput = event.currentTarget;

    if (event.key === 'Backspace') {
      const parentDiv = currentInput.parentElement;
      const prevDiv = parentDiv.previousElementSibling;

      if (prevDiv) {
        const prevInput = prevDiv.querySelector('input');
        if (prevInput) {
          prevInput.focus();
          prevInput.value = ""
        }
        return
      }
    }

    // Prevent user from entering more than 1 digit.
    // If inputted new value it saves the new inputted value.
    if(currentInput.value.length > 1){
      currentInput.value = currentInput.value.slice(1, 2)
    }

    // Allow only integer input
    if (event.key === " " || !/^\d*$/.test(event.key)) {
      return
    }

    const nextDiv = parentDiv.nextElementSibling;
    if (nextDiv) {
      parentDiv.classList.remove("border-blue-500")
      const nextInput = nextDiv.querySelector('input');
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  focusIn(event){
    event.currentTarget.parentElement.classList.add("border-blue-500")
  }

  focusOut(event){
    event.currentTarget.parentElement.classList.remove("border-blue-500")
  }

  redirect(event){
    window.location.href = event.currentTarget.dataset.urlValue
  }

  disableButtons(event){
    const parentElement = event.currentTarget
    const buttons = parentElement.querySelectorAll("button")

    buttons.forEach((button) => {
      button.disabled = true
    })
  }
}