
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { isDesktop: {type: Boolean, default: true} }
  static targets = [
    "desktopParent",
    "desktopChild",
    "approveButton",
    "approvalFlowAction",
    "approvalFlowResourceIds",
    "mobileChild"
  ];

  connect() {
    this.desktopChildTargets.map(x => x.checked = false)
    this.mobileChildTargets.map(x => x.checked = false)
    this.desktopParentTarget.checked = false
    this.approveButtonTargets.forEach((el) => {
      el.disabled = true
      el.classList.remove("bg-purple-700")
      el.classList.add("bg-purple-300")
    })
  }

  toggleDesktopChildren() {
    this.removeCheckedOnMobileChildren();
    this.isDesktopValue = true;
    const parentSelected = this.desktopParentTarget.checked
    if (parentSelected) {
      this.desktopChildTargets.map(x => x.checked = true)
    } else {
      this.desktopChildTargets.map(x => x.checked = false)
    }

    this.updateButtonState();
  }

  toggleMobileParent(){
    this.removeCheckedOnDesktopChildren();
    this.isDesktopValue = false;
    this.updateButtonState()
  }

  toggleDesktopParent() {
    this.removeCheckedOnMobileChildren();
    this.isDesktopValue = true;
    const withUnselectedChild = this.desktopChildTargets.map(x => x.checked).includes(false);
    if (withUnselectedChild) {
      this.desktopParentTarget.checked = false;
    } else {
      this.desktopParentTarget.checked = true;
    }

    this.updateButtonState();
  }

  removeCheckedOnDesktopChildren() {
    this.desktopChildTargets.map(x => x.checked = false)
    this.desktopParentTarget.checked = false;
    this.updateButtonState();
  }

  removeCheckedOnMobileChildren() {
    this.mobileChildTargets.map(x => x.checked = false)
    this.updateButtonState();
  }

  updateButtonState() {
    let withSelectedChild = false

    if (this.isDesktopValue) {
      withSelectedChild = this.desktopChildTargets.map(x => x.checked).includes(true);
    } else {
      withSelectedChild = this.mobileChildTargets.map(x => x.checked).includes(true);
    }

    if (withSelectedChild) {
      this.approveButtonTargets.forEach((el) => {
        el.disabled = false
        el.classList.add("bg-purple-700")
        el.classList.remove("bg-purple-300")
      })
    } else {
      this.approveButtonTargets.forEach((el) => {
        el.disabled = true
        el.classList.remove("bg-purple-700")
        el.classList.add("bg-purple-300")
      })
    }
  }

  approve() {
    this.approvalFlowActionTargets.forEach((el) => el.value = "approved")
    this.approvalFlowResourceIdsTarget.value = ""

    if (this.isDesktopValue) {
      this.approvalFlowResourceIdsTarget.value = this.desktopChildTargets.filter(x => x.checked).map(x => x.value).join(',')
    } else {
      this.approvalFlowResourceIdsTarget.value = this.mobileChildTargets.filter(x => x.checked).map(x => x.value).join(',')
    }

    document.getElementById("batchTransferForm").submit();
  }
}