import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  approveSelected() {
    const selectedIds = Array.from(document.querySelectorAll('.change-request-checkbox:checked'))
      .map(checkbox => checkbox.value);
      
    if (selectedIds.length === 0) return;
    
    fetch('/contacts/batch_approve_change_requests', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({ change_request_ids: selectedIds })
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        window.location.reload();
      } else {
        alert(data.message);
      }
    });
  }
}
