import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    suppliers: { type: Array, default: [] },
    step: { type: String, default: "selectRecipientsStep" }
  }

  static targets = [
    "reviewAndDownloadButton",
    "searchInput",
    "recipientBusinessName",
    "noRecipientContainer",
    "stepContainer",
    "tabTitle",
    "sortingTab",
    "activeTabInput",
  ]

  initialize(){
    this.selectRecipientsStep = "selectRecipientsStep"
    this.downloadYourTemplateStep = "downloadYourTemplateStep"
    this.recipientsContainer = document.getElementById("recipientsContainer")
    this.recipientsLoadingIndicator = document.getElementById("recipientsLoadingIndicator")
    this.showCurrentTab()
  }

  connect(){
    // Listen for the beforeRequest event to be fired
    document.addEventListener('htmx:beforeRequest', this.handleBeforeRequest);
    document.addEventListener('htmx:afterRequest', this.handleAfterRequest);

    let loadingSkeleton = document.getElementById('stepLoadingSkeleton')
    let container = document.getElementById('selectRecipientsContainer')

    loadingSkeleton.classList.add("hidden")
    container.classList.remove("hidden")
  }

  // Disable the review and download button when changing tabs
  handleBeforeRequest = (event) => {
    if(event.detail.target.id == "recipientsContainer"){
      this.suppliersValue = []
      this.disableReviewAndDownloadButton()
      this.recipientsContainer.classList.add("hidden")
      this.recipientsLoadingIndicator.classList.remove("hidden")
    }
  }

  handleAfterRequest = (event) => {
    if(event.detail.target.id == "recipientsContainer"){
      this.recipientsContainer.classList.remove("hidden")
      this.recipientsLoadingIndicator.classList.add("hidden")
    }
  }

  // Search Input
  search(event){
    const targetValue = event.target.value.trim()

    let noMatchesFound = true
    this.noRecipientContainerTarget.classList.add("hidden")

    this.recipientBusinessNameTargets.forEach((element) => {
      const textContent = element.textContent.trim()
      const regex = new RegExp(targetValue, "gi")

      // Replace the matched text with highlighted version
      const highlightedText = textContent.replace(regex, (match) =>  `<span class="text-purple-700">${match}</span>`)

      element.innerHTML = highlightedText;

      if(regex.test(textContent)){
        element.parentNode.classList.remove("hidden")
        noMatchesFound = false
      } else {
        element.parentNode.classList.add("hidden")
      }
    })

    if(noMatchesFound){
      this.noRecipientContainerTarget.classList.remove("hidden")
    }
  }

  // Selecting and Deselecting Recipients

  setSelectedRecipients(event){
    if(event.currentTarget.checked){
      this.addRecipient(event.currentTarget)
    } else {
      this.removeRecipient(event.currentTarget)
    }
  }

  addRecipient(input){
    const parsedSupplier = (JSON.parse(input.value))[0]

    const foundSupplierIndex = this.suppliersValue.findIndex((supplier) => {
      return supplier.recipientId == parsedSupplier.recipientId
    })

    if(foundSupplierIndex != -1){
      this.addBankAccountToExistingSupplier(foundSupplierIndex, parsedSupplier)
    } else {
      this.suppliersValue = [...this.suppliersValue, ...JSON.parse(input.value)]
    }

    this.enableReviewAndDownloadButton()
  }

  addBankAccountToExistingSupplier(foundSupplierIndex, parsedSupplier){
    const updatedSuppliers = [...this.suppliersValue]
    const foundSupplier = this.suppliersValue[foundSupplierIndex]

    const newSupplier = {
      ...foundSupplier,
      bankAccounts: [...foundSupplier.bankAccounts, ...parsedSupplier.bankAccounts]
    }

    updatedSuppliers[foundSupplierIndex] = newSupplier
    this.suppliersValue = updatedSuppliers
  }

  removeRecipient(input){
    const parsedSupplier = (JSON.parse(input.value))[0]

    const foundSupplierIndex = this.suppliersValue.findIndex((supplier) => {
      return supplier.recipientId == parsedSupplier.recipientId
    })

    const foundSupplier = this.suppliersValue[foundSupplierIndex]

    if(foundSupplierIndex != -1 && foundSupplier.bankAccounts.length > 1){
      this.removeBankAccountFromExistingSupplier(foundSupplierIndex, foundSupplier, parsedSupplier)
    } else {
      this.suppliersValue = this.suppliersValue.filter((supplier) => {
        return supplier.recipientId != parsedSupplier.recipientId
      })
    }

    this.disableReviewAndDownloadButton()
  }

  removeBankAccountFromExistingSupplier(foundSupplierIndex, foundSupplier, parsedSupplier){
    const updatedSuppliers = [...this.suppliersValue]

    const filteredSupplierBankAccounts = foundSupplier.bankAccounts.filter((bankAccount) => {
      return bankAccount.id != parsedSupplier.bankAccounts[0].id
    })

    const updatedSupplier = {
      recipientId: foundSupplier.recipientId,
      businessName: foundSupplier.businessName,
      bankAccounts: filteredSupplierBankAccounts
    }

    updatedSuppliers[foundSupplierIndex] = updatedSupplier
    this.suppliersValue = updatedSuppliers
  }

  enableReviewAndDownloadButton(){
    this.reviewAndDownloadButtonTargets.forEach((element) => {
      element.disabled = false
      element.classList.remove("bg-purple-300", "cursor-not-allowed")
      element.classList.add("bg-purple-700", "cursor-pointer")
    })
  }

  disableReviewAndDownloadButton(){
    if(this.suppliersValue.length == 0){
      this.reviewAndDownloadButtonTargets.forEach((element) => {
        element.disabled = true
        element.classList.add("bg-purple-300", "cursor-not-allowed")
        element.classList.remove("bg-purple-700", "cursor-pointer")
      })
    }
  }

  // Sorting Recipients

  sortByRecent(event){
    this.removeActiveTabClasses()
    event.currentTarget.classList.add("active", "text-purple-700", "border-b-2", "border-purple-700")
    htmx.trigger(event.currentTarget, "sortByRecent")
    this.activeTabInputTarget.value = "recent"
  }

  sortByAlphabetical(event){
    this.removeActiveTabClasses()
    event.currentTarget.classList.add("active", "text-purple-700", "border-b-2", "border-purple-700")
    htmx.trigger(event.currentTarget, "sortByAlphabetical")
    this.activeTabInputTarget.value = "alphabetical"
  }

  removeActiveTabClasses(){
    this.sortingTabTargets.forEach((element) => {
      element.classList.remove("active", "text-purple-700", "border-b-2", "border-purple-700")
    })
  }

  // Navigation

  nextStep(event){
    this.stepValue = event.currentTarget.dataset.nextStepValue.trim()
    if(this.stepValue == this.downloadYourTemplateStep){
      this.populateSelectedRecipientsContainer()
      this.reviewAndDownloadButtonTargets.forEach((element) => {
        const parentElement = element.parentElement
        parentElement.classList.add("hidden")
      })
    }
    this.showCurrentTab()
  }

  navigateToNext(){
    this.populateSelectedRecipientsContainer()
    const paymentBankAccountIds = document.querySelectorAll("input[name='batch_transfer[external_bank_account_ids]']")
    const queryParams = Array.from(paymentBankAccountIds).map((element) => `external_bank_account_ids[]=${element.value}`).join('&')
    window.location.href = `/v1/batch_transfers/new_v2?${queryParams}`
  }

  goBack(){
    if(this.stepValue == this.selectRecipientsStep){
      history.back()
    } else {
      this.stepValue = this.selectRecipientsStep
      this.reviewAndDownloadButtonTargets.forEach((element) => {
        const parentElement = element.parentElement
        parentElement.classList.remove("hidden")
      })
    }

    this.showCurrentTab()
  }

  showCurrentTab(){
    this.stepContainerTargets.forEach((element) => {
      if(element.dataset.stepValue == this.stepValue){
        this.tabTitleTarget.textContent = element.dataset.title
        element.classList.remove("hidden")
      } else {
        element.classList.add("hidden")
      }
    })
  }

  populateSelectedRecipientsContainer(){
    const selectedRecipientsContainer = document.getElementById("selectedRecipientsContainer");
    selectedRecipientsContainer.innerHTML = this.suppliersValue.map((supplier, index) => this.createSupplierElement(supplier, index)).join('')
  }

  createSupplierElement(supplier, index){
    const lastItem = (this.suppliersValue.length == index + 1)
    return `
        <div class="flex flex-row gap-4 ${lastItem ? "" : "pb-6"}">
          <h2 class="font-bold">${index + 1}.</h2>
          <div class="flex flex-col gap-2">
            <h2 class="font-bold">${supplier.businessName}</h2>
            ${supplier.bankAccounts.map(bankAccount => this.createBankAccountElement(bankAccount)).join('')}
          </div>
        </div>
    `;
  }

  createBankAccountElement(bankAccount) {
    const formattedBankAccount = bankAccount.bankName.split("(")[0].trim();
    const formattedBankAccountNumber = bankAccount.number.replace(/[^0-9]/g, '');

    return `
      <input type="hidden" name="batch_transfer[external_bank_account_ids]" value="${bankAccount.id}"/>
      <p class="text-gray-500">${bankAccount.name} - ${formattedBankAccount} ${formattedBankAccountNumber}</p>
    `;
  }
}