import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "fileInput",
    "removeStagedFile",
    "uploadBatchTransfer",
    "nextStepButton"
  ]

  initialize(){
    this.downloadTemplateElement = document.getElementById('downloadTemplate')
    this.container = document.getElementById('uploadBatchFileContainer')
  }

  connect() {
    let loadingSkeleton = document.getElementById('stepLoadingSkeleton')

    loadingSkeleton.classList.add("hidden")
    this.container.classList.remove("hidden")
    this.removeStagedFile()
  }

  // Showing the continue button

  stageFile(){
    let fileInput = this.fileInputTarget
    let removeStagedFile = this.removeStagedFileTarget
    let uploadBatchTransfer = this.uploadBatchTransferTarget
    let nextStepButton = this.nextStepButtonTarget

    if(fileInput.files.length > 0) {
      removeStagedFile.classList.remove("hidden")
      uploadBatchTransfer.classList.remove("hidden")
      nextStepButton.disabled = false
    }
  }

  removeStagedFile() {
    let fileInput = this.fileInputTarget
    let removeStagedFile = this.removeStagedFileTarget
    let uploadBatchTransfer = this.uploadBatchTransferTarget

    removeStagedFile.classList.add("hidden")

    fileInput.value = ""
  }

  // Downloading a Template

  downloadTemplate() {
    this.fetchTemplate()
  }

  fetchTemplate() {
    this.showLoadingIndicator(this.downloadTemplateElement)
    const a = document.createElement("a")
    a.href = "/batch_transfers/template"
    a.click()
    a.remove()
    this.removeLoadingIndicator(this.downloadTemplateElement)
  }

  showLoadingIndicator(buttonElement) {
    const buttonTextElement = buttonElement.querySelector("p")
    const loadingIndicatorElement = buttonElement.children[0]
    const buttonIconElement = buttonElement.children[1]
    buttonTextElement.classList.add("hidden")
    buttonIconElement.classList.add("hidden")
    loadingIndicatorElement.classList.remove("hidden")
    buttonElement.disabled = true
    buttonElement.classList.remove("hover:bg-purple-600", "hover:text-white", "hover:border-purple-600", "cursor-pointer")
    buttonElement.classList.add("cursor-not-allowed")
  }

  removeLoadingIndicator(buttonElement){
    const buttonTextElement = buttonElement.querySelector("p")
    const loadingIndicatorElement = buttonElement.children[0]
    const buttonIconElement = buttonElement.children[1]
    buttonTextElement.classList.remove("hidden")
    buttonIconElement.classList.remove("hidden")
    loadingIndicatorElement.classList.add("hidden")
    buttonElement.disabled = false
    buttonElement.classList.remove("cursor-not-allowed")
  }

  redirect(event){
    window.location.href = event.currentTarget.dataset.urlValue
  }

  uploadBatchTransferFile(){
    const loadingStep = document.getElementById("loadingStep")

    this.container.classList.add("hidden")
    loadingStep.classList.remove("hidden")
    const batchTransferForm = document.getElementById('batchTransferForm')
    batchTransferForm.submit()
  }
}