
import { Controller } from "@hotwired/stimulus"
import { debounce } from "../utils/lodash.js"

const TIMEOUT = 14 // minutes

export default class extends Controller {
  static values = { timeout: {type: Number, default: TIMEOUT }, baseurl: String }

  initialize(){
    // https://fullstackheroes.com/tutorials/stimulus/debounce-throttle/
    this.resetTimer = debounce(this.resetTimer, 500).bind(this)
    this.intervalId = null;
  }

  connect(){
    this.addEventListeners()
    this.defaultTimeoutValue = this.timeoutValue

    this.startTimer()
  }

  startTimer(){
    this.timeoutInterval()
  }

  timeoutInterval(){
    let timeout = this.defaultTimeoutValue * 60

    if(this.intervalId){
      clearInterval(this.intervalId)
    }

    this.intervalId = setInterval(() => {
      timeout -= 1
      if(timeout <= 0){
        this.logout()
      }
    }, 1000)
  }

  resetTimer() {
    this.timeoutValue = this.defaultTimeoutValue

    if(this.intervalId){
      clearInterval(this.intervalId)
    }

    this.startTimer()
  }

  addEventListeners(){
    window.onmousemove = this.resetTimer
    window.onmousedown = this.resetTimer
    window.onclick = this.resetTimer
    window.onscroll = this.resetTimer
    window.onkeydown = this.resetTimer
  }

  removeEventListeners(){
    window.onmousemove = null
    window.onmousedown = null
    window.onclick = null
    window.onscroll = null
    window.onkeydown = null
  }

  logout(){
    clearInterval(this.intervalId)
    this.removeEventListeners()
    window.location.href = `${this.baseurlValue}users/sign_out`
  }
}