import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { step: { type: String, default: "uploadBatchTransferStep" } }
  static targets = [
    "batchTransferStep",
    "selectRecipients",
  ]

  initialize() {
    this.selectRecipientsStep = "selectRecipientsStep"
    this.downloadYourTemplateStep = "downloadYourTemplateStep"
  }

  goBack(){
    history.back()
  }

  uploadBatchTransferFile() {
    if (this.stepValue == this.processingFileStep) {
      const batchTransferForm = document.getElementById("batchTransferForm")
      batchTransferForm.submit();
    }
  }
}